<template>
  <li class="list-group-item px-4 py-0">
    <div class="row d-flex align-items-center">
      <div class="col-2">
        <p class="fw-bold">
          {{ header }}
        </p>
      </div>
      <div class="col d-flex justify-content-between align-items-center">
        <p class="text-typography-primary">
          {{ value }}
        </p>
        <Button v-if="buttonEnabled" type="text" @click="$emit('click')">
          {{ buttonText }}
        </Button>
      </div>
    </div>
  </li>
</template>

<script type="text/javascript">
import { Button } from '@/modules/core';

export default {
  components: { Button },
  props: {
    buttonEnabled: { type: Boolean, default: false },
    buttonText: { type: String, required: true },
    type: { type: String, required: true },
    header: { type: String, required: true },
    value: { type: String, default: '-' },
  },
};
</script>

<style lang="scss" scoped>
li .row {
  height: 54px;
}
</style>
