import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useMutation, useQuery, useResult } from '@vue/apollo-composable';

export function useBusinessPatch() {
  const { mutate, loading, onDone, onError } = useMutation(BUSINESS_PATCH_MUTATION);

  return {
    updateBusiness: mutate,
    loading,
    onDone,
    onError,
  };
}

const BUSINESS_PATCH_MUTATION = gql`
  mutation businessPatch($businessId: ID!, $patchParams: BusinessPatchInput!) {
    businessPatch(businessId: $businessId, patchParams: $patchParams) {
      id
      name
      number
      type
      logoUrl
      legalName
      tenant
      contact {
        name
        phone
        address
        email
      }
    }
  }
`;

export const LOGO_FILE_UPLOAD_PERMISSION = gql`
  query getLogoFileUploadPermission($businessId: ID!, $filename: String!) {
    getLogoFileUploadPermission(businessId: $businessId, filename: $filename) {
      filePath
      url
      fields
      photoUrl
    }
  }
`;

export function useBusinessById(variables) {
  const { result, loading, onError, refetch } = useQuery(BUSINESS_BY_ID, variables, () => ({
    enabled: !!variables.value.businessId,
  }));
  const business = useResult(result, { business: {} });

  return {
    business,
    loading,
    refetch,
    onError,
  };
}

const BUSINESS_BY_ID = gql`
  query getBusinessById($businessId: ID!) {
    getBusinessById(businessId: $businessId) {
      id
      name
      logoUrlSigned
      countryCode
      logoUrl
      name
      number
    }
  }
`;

export function useBusinessByNames({ businessIds }) {
  const { result, loading, onError, refetch, onResult } = useQuery(BUSINESS_BY_NAMES_NEW, { businessIds }, () => ({
    enabled: businessIds.value.length,
  }));
  const business = computed(() => (result.value ? result.value.getBusinessNames?.nodes ?? [] : []));

  return {
    result: business,
    loading,
    refetch,
    onError,
    onResult,
  };
}

const BUSINESS_BY_NAMES_NEW = gql`
  query getBusinessNames($businessIds: [ID!]) {
    getBusinessNames(businessIds: $businessIds) {
      nodes {
        id
        name
      }
    }
  }
`;
