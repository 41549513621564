import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';

export function useSuppliers() {
  const { result, loading, onError, refetch } = useQuery(SUPPLIERS_BUSINESSES, {});
  const business = useResult(result, []);

  return {
    suppliers: business,
    loading,
    refetch,
    onError,
  };
}

const SUPPLIERS_BUSINESSES = gql`
  query getSuppliers {
    getSuppliers {
      id
      name
      number
    }
  }
`;

export function useSuppliersNew() {
  const { result, loading, onError, refetch } = useQuery(SUPPLIERS_BUSINESSES_NEW, {});

  const business = computed(() => (result.value ? result.value.getSuppliersNew?.nodes ?? [] : []));

  return {
    suppliers: business,
    loading,
    refetch,
    onError,
  };
}

const SUPPLIERS_BUSINESSES_NEW = gql`
  query getSuppliers {
    getSuppliersNew {
      nodes {
        id
        name
        number
      }
    }
  }
`;
