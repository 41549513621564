<template>
  <el-dialog
    width="507px"
    visible
    :show-close="false"
    append-to-body
    custom-class="rounded settings-modal"
    @close="close"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-4 border-bottom">
        <h2 class="text-typography-primary">
          {{ translate(`table.headers.${type}`) }}
        </h2>
        <Button type="text" class="p-0 text-typography-primary actions-btn" @click="close">
          <CloseIcon width="24" height="24" />
        </Button>
      </div>
    </template>
    <div v-loading="loading" class="py-4 px-4">
      <el-form ref="form" :model="formModel" :show-message="false" :rules="formValidationRules" @submit.native.prevent>
        <template v-if="type === 'logo'">
          <div class="d-flex align-items-center flex-column mb-7">
            <el-form-item prop="logo.url">
              <div class="d-flex justify-content-center flex-column">
                <el-upload ref="upload" action="#" drag :http-request="uploadFile">
                  <Button ref="uploadButton" type="text" class="p-0">
                    <template v-if="formModel.logo">
                      <template v-if="isURL(formModel.logo.url)">
                        <img :src="`${formModel.logo.url}&token=${token}`" width="80" height="80" class="mb-4" />
                      </template>
                      <template v-else>
                        <img :src="formModel.logo.url" width="80" height="80" class="mb-4" />
                      </template>
                    </template>
                    <template v-else>
                      <img src="@/assets/images/business.png" width="80" height="80" class="mb-4" />
                    </template>
                  </Button>
                </el-upload>
                <Button type="text" class="actions-btn" @click="handlePictureSubtitleClick">
                  <p class="fw-bold" :class="formModel.logo ? 'text-danger' : 'text-typography-primary'">
                    {{ translate(`modal.logo.${formModel.logo ? 'remove' : 'upload'}`) }}
                  </p>
                </Button>
              </div>
            </el-form-item>
          </div>
          <small class="text-muted">
            {{ translate('modal.logo.subtitle') }}
          </small>
        </template>
        <template v-else-if="type === 'name'">
          <div class="row gx-4">
            <el-form-item prop="name" class="col">
              <div class="w-50 mb-5">
                <el-input v-model="formModel.name" size="small" />
              </div>
              <small class="text-muted text-break">
                {{ translate('modal.name.subtitle') }}
              </small>
            </el-form-item>
          </div>
        </template>
        <template v-else-if="type === 'legalName'">
          <div class="row gx-4">
            <el-form-item prop="legalName" class="col" :error="showConflict ? 'conflict' : null">
              <div class="w-50 mb-5">
                <el-input v-model="formModel.legalName" size="small" />
              </div>
              <small class="text-muted text-break">
                {{ translate('modal.legalName.subtitle') }}
              </small>
            </el-form-item>
          </div>
        </template>
        <template v-else-if="type === 'number'">
          <div class="row gx-4">
            <el-form-item prop="number" class="col" :error="showConflict ? 'conflict' : null">
              <div class="w-50 mb-5">
                <el-input v-model="formModel.number" size="small" />
              </div>
            </el-form-item>
          </div>
        </template>
        <template v-else-if="type === 'address'">
          <div class="row gx-4">
            <el-form-item prop="address" class="col">
              <div class="w-50 mb-5">
                <el-input v-model="formModel.address" size="small" />
              </div>
            </el-form-item>
          </div>
        </template>
        <template v-else-if="type === 'phone'">
          <div class="row gx-4">
            <el-form-item prop="phone" class="col">
              <div class="w-50 mb-5">
                <el-input v-model="formModel.phone" size="small" />
              </div>
              <small class="text-muted text-break">
                {{ translate('modal.phone.subtitle') }}
              </small>
            </el-form-item>
          </div>
        </template>
        <template v-else-if="type === 'primaryContact'">
          <div class="row gx-4">
            <el-form-item prop="primaryContact" class="col">
              <div class="w-50 mb-5">
                <el-input v-model="formModel.primaryContact" size="small" />
              </div>
            </el-form-item>
          </div>
        </template>
      </el-form>
      <PictureEditModal
        v-if="pictureEditModalOpen"
        :url="formModel.logo.url"
        @close="handlePictureEditModalClose"
        @pictureUpload="handlePictureUpload"
      />
    </div>
    <template #footer>
      <div class="pb-4 px-4">
        <Button type="secondary" @click="close">
          {{ $t('commons.cancel') }}
        </Button>
        <Button @click="submit">
          {{ $t('commons.save') }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { Button, PictureEditModal } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';

import { LOGO_FILE_UPLOAD_PERMISSION } from '../../compositions/business';

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const isURL = (str) => new RegExp(/^http/).test(str);

export default {
  components: { PictureEditModal, Button, CloseIcon },
  props: {
    type: { type: String, required: true },
    business: { type: Object, required: true },
    token: { type: String, required: true },
    showConflict: { type: Boolean, default: false },
  },
  setup() {
    const formValidationRules = {
      ['logo.url']: { type: 'string', required: true },
      name: { type: 'string', required: true },
      legalName: { type: 'string', required: true },
      number: { type: 'string', pattern: /\d+/, required: true },
      ['address.city']: { type: 'string', required: true },
      ['address.street']: { type: 'string', required: true },
      ['address.streetNo']: { type: 'string', pattern: /\d+/, required: true },
      ['address.zipCode']: { type: 'string', pattern: /\d{4,7}/, required: true },
      phone: { type: 'string', pattern: /^05\d(([-]{0,1}\d{7})|(\d-\d{6}))$/, required: true },
      primaryContact: { type: 'string', required: true },
    };

    return {
      loading: false,
      formValidationRules,
      isURL,
    };
  },
  data() {
    return {
      pictureEditModalOpen: false,
      formModel: {
        logo: this.business.logoUrl ? { url: this.business.logoUrl } : null,
        name: this.business.name,
        legalName: this.business.legalName,
        number: this.business.number,
        address: this.business.contact.address,
        phone: this.business.contact.phone,
        primaryContact: this.business.contact.name,
      },
    };
  },
  methods: {
    handlePictureEditModalClose() {
      this.pictureEditModalOpen = false;
      this.formModel.logo = this.business.logoUrl ? { url: this.business.logoUrl } : null;
    },
    uploadFile({ file }) {
      const isBiggerThan5MB = file.size / 1024 / 1024 > 5;
      if (isBiggerThan5MB) {
        this.$message.error(this.translate('messages.fileTooBig'));
        return;
      }
      const isImage = new RegExp(/^image\//).test(file.type);
      if (!isImage) {
        this.$message.error(this.translate('messages.notAnImage'));
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.formModel.logo = { url: reader.result };
        this.pictureEditModalOpen = true;
      };
    },
    handlePictureSubtitleClick() {
      this.formModel.logo ? this.$emit('removeLogo') : this.$refs.uploadButton.$el.click();
    },
    translate(key) {
      return this.$t(`businessSettings.generalSettings.${key}`);
    },
    async handlePictureUpload(file) {
      this.pictureEditModalOpen = false;
      this.formModel.logo = { url: await toBase64(file), blob: file };
    },
    submit() {
      this.loading = true;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.type === 'logo') {
            if (this.formModel.logo?.blob) {
              await this.uploadLogo(this.formModel.logo?.blob);
            } else {
              this.$emit('close');
            }
          }

          const data = this.formModel[this.type];
          this.$emit('update', data);
        }
        this.loading = false;
      });
    },
    async uploadLogo(file) {
      const {
        data: {
          getLogoFileUploadPermission: { url, fields, photoUrl },
        },
      } = await this.$apollo.query({
        query: LOGO_FILE_UPLOAD_PERMISSION,
        variables: {
          businessId: this.business.id,
          filename: 'logo.png',
        },
      });
      const parsedFields = JSON.parse(fields);
      const formData = new FormData();
      Object.keys(parsedFields).forEach((key) => formData.append(key, parsedFields[key]));
      formData.append('file', file);
      const response = await fetch(url, { method: 'POST', body: formData });
      if (!response.ok) throw new Error();
      const randomString = (Math.random() + 1).toString(36).substring(7);
      this.formModel.logo = { url: `${photoUrl}?rand=${randomString}`, blob: file };
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .settings-modal {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
}

::v-deep .el-radio__input {
  padding-top: 3px;
}

::v-deep .el-form-item {
  margin-bottom: 0;
}

.actions-btn {
  cursor: pointer;
  &:hover {
    background: $secondary;
  }
  &.active {
    visibility: visible;
  }
}

::v-deep .el-form-item__content {
  line-height: 1.5;
}

::v-deep .el-upload {
  width: 100%;
}
::v-deep .el-upload-dragger {
  width: 100%;
  display: flex;
  border: none;
  height: auto;
  flex-direction: column;
  justify-content: center;
}

::v-deep .el-upload-list {
  display: none;
}
</style>
